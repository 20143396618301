import React from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery, Link } from "gatsby"
import { SectionTitle } from "../components/styled"
import WrapperRight from "../components/WrapperRight"
import Contact from "../components/Contact"
import { Seo } from "../components/seo"
const JobSeekers = () => {
  const queryImage = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)|(webp)/" }
          relativeDirectory: { eq: "others" }
        }
        sort: { order: ASC, fields: modifiedTime }
      ) {
        nodes {
          childImageSharp {
            fluid(jpegQuality: 100, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  `)
  const images = queryImage.allFile.nodes
  return (
    <>
      <Seo
        title={`メキシコ求人・就職・転職情報 | メキシコの求人・転職・就職情報【QUICK GLOBAL MEXICO】`}
        image={images[0].childImageSharp.fluid.src}
      />
      <section className="container job-seekers">
        <div className="row">
          <div className="col-lg-9 col-lg-9">
            <div className="job-seekers__for-job-seekers">
              <SectionTitle>メキシコ求人・就職・転職情報</SectionTitle>
              <hr className="u-line-bottom" />
              <div className="job-seekers__for-job-seekers--box">
                <Img fluid={images[0].childImageSharp.fluid} />
                <p>
                  クイックグローバルメキシコはメキシコのアグアスカリエンテス州を拠点に、メキシコ各地の日系企業でのお仕事を専門に紹介している日系人材紹介会社です。メキシコ国内はもちろん、日本やその他の国からの就職・転職もサポートしておりますので、まずはこちらをご確認ください
                </p>
                <div className="buttons-job-seeker">
                  <Link to="/to-residents-in-mexico">
                    メキシコにお住まいの方へ
                  </Link>
                  <Link to="/to-residents-in-japan-and-other-countries">
                    日本及びその他の国にお住まいの方へ
                  </Link>
                </div>
              </div>
            </div>
            <Contact />
          </div>
          <WrapperRight />
        </div>
      </section>
    </>
  )
}

export default JobSeekers
